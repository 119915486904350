import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../constants/apiRoutes";
import { setMessage } from "../reducers/messageReducer";
import AuthService from "../services/auth.service";
import { getError } from "../services/CommonApi";
import cookiesService from "../services/cookies.service";
import { toast } from "react-toastify";
import i18n from "i18next";

export const getCurrentUser = createAsyncThunk(
  API_ROUTES.GET_CURRENT_USER_DETAILS_URL,
  async (_, thunkAPI) => {
    try {
      const token = cookiesService.getItem("token");
      if (token) {
        const userResponse = await AuthService.getCurrentUserDetails();
        const universityName = userResponse && userResponse.data.data.university.name;
        // console.log("token====>",universityName);
        cookiesService.setItem("universityName", universityName);
        const user = userResponse && userResponse.data;
        if (user && user.status) {
          return { user: user.data };
        } else {
          throw new Error("Unable to fetch user details.");
        }
      } else {
        throw new Error("Login failed.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  API_ROUTES.LOGIN_URL,
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await AuthService.login(email, password);
      const token = response && response.data && response.data.token;
   
      const forceChangePassword =
        response && response.data && response.data.force_change_password;
      const uid = response && response.data && response.data.uid;
      const changePasswordToken =
        response && response.data && response.data.token;

      const status = response && response.data;
     
      if (token) {
        if (status) {
          if (!forceChangePassword) {
            cookiesService.setItem("token", token);
          }
          return {
            updateLogin: !forceChangePassword,
            uid: uid,
            token: changePasswordToken,
          };
          } else {
             throw new Error("Login failed, this account has been deactivated.");
           }
        } else {
          throw new Error(
            i18n.t("The e-mail address and/or password is/are incorrect. Please enter the correct credentials")
          );
        }
      
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changePassword = createAsyncThunk(
  API_ROUTES.CHANGE_PASSWORD,
  async ({ currentPassword, newPassword, confirmNewPassword }, thunkAPI) => {
    try {
      const data = await AuthService.changePassword(
        currentPassword,
        newPassword,
        confirmNewPassword
      );
      if (data && data.status === 200) {
        return true;
      } else {
        throw new Error("Change password failed.");
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk(
  API_ROUTES.LOGOUT_URL,
  async (_, thunkAPI) => {
    try {
      const token = cookiesService.getItem("token");
      if (token) {
        const data = await AuthService.logout();
        if (data && data.status === 200) {
          cookiesService.removeItem("token");
          return true;
        } else {
          throw new Error("Logout failed.");
        }
      } else {
        cookiesService.removeItem("token");
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendEmailOtp = createAsyncThunk(
  API_ROUTES.SEND_EMAIL_OTP_PASSWORD_RESET,
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.sendEmailOtp(email);
      if (response && response.status === 200) {
        toast.success(i18n.t("OTP sent to Email."));
        return true;
      } else {
        toast.error(i18n.t("Request new password failed."));
        return false;
      }
    } catch (error) {
      toast.error(i18n.t("Request new password failed."));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resendEmailOtp = createAsyncThunk(
  API_ROUTES.RESEND_EMAIL_OTP_PASSWORD_RESET,
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.resendEmailOtp(email);
      if (response && response.status === 200) {
        toast.success(i18n.t("OTP re-sent to Email."));
        return true;
      } else {
        toast.error(i18n.t("Request new password failed."));
        return false;
      }
    } catch (error) {
      toast.error(i18n.t("Request new password failed."));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const verifyEmailOtp = createAsyncThunk(
  API_ROUTES.VERIFY_EMAIL_OTP_PASSWORD_RESET,
  async ({ email, code }, thunkAPI) => {
    try {
      const response = await AuthService.verifyEmailOtp(email, code);
      if (response && response.status && response && response.data) {
        toast.success(i18n.t(response.data.message));
        const { token, uid } = response && response.data && response.data.data;
        return { token, uid };
      } else {
        toast.error(i18n.t("OTP verification failed."));
        return false;
      }
    } catch (error) {
      const message = getError(error);
      toast.error(message || i18n.t("OTP verification failed."));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forceChangePassword = createAsyncThunk(
  API_ROUTES.FORCE_CHANGE_PASSWORD,
  async ({ uid, token, newPassword, confirmNewPassword }, thunkAPI) => {
    try {
      const data = await AuthService.forceChangePassword(
        uid,
        token,
        newPassword,
        confirmNewPassword
      );
      if (data && data.status === 200) {
        return true;
      } else {
        throw new Error("Change password failed.");
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPassword = (code, email, password) => () => {
  return AuthService.resetPassword(code, email, password).then(
    () => {
      return Promise.resolve();
    },
    () => {
      return Promise.reject();
    }
  );
};
