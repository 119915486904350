export const CONSTANTS = {
  version: "1.0.0",
  HOME_AUTHORIZATION_FOR: ["Analyst", "Coach", "University Admin"],
  USER_MANAGEMENT_AUTHORIZATION_FOR: ["University Admin"],
  ANALYTICAL_DASHBOARD_AUTHORIZATION_FOR: ["Analyst", "University Admin"],
  CUSTOM_REPORT_BUILDER_AUTHORIZATION_FOR: ["Analyst", "University Admin"],
  COACHING_DASHBOARD_AUTHORIZATION_FOR: ["Coach", "University Admin"],
  SCHEDULING_AUTHORIZATION_FOR: ["Coach", "University Admin"],
  UNIVERSITY_AUTHORIZATION_FOR: ["University Admin"],
  SETTINGS_AUTHORIZATION_FOR: ["Analyst", "Coach", "University Admin"],
  HELP_AUTHORIZATION_FOR: ["Analyst", "Coach", "University Admin"],
  SEMESTER_DATA: [
    {
      "label": "Semester 1",
      "value": "1"
    },
    {
      "label": "Semester 2",
      "value": "2"
    },
    {
      "label": "Semester 3",
      "value": "3"
    },
    {
      "label": "Semester 4",
      "value": "4"
    },
    {
      "label": "Semester 5",
      "value": "5"
    },
    {
      "label": "Semester 6",
      "value": "6"
    },
    {
      "label": "Semester 7",
      "value": "7"
    },
    {
      "label": "Semester 8",
      "value": "8"
    }
  ]
};
