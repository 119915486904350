import React, { useState } from "react";
import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Help } from "../../assets/svg/helpIcon.svg";
import { ReactComponent as Analytical } from "../../assets/svg/analytical.svg";
import { ReactComponent as CustomReportBuilder } from "../../assets/svg/Vector.svg";
import { ReactComponent as Home } from "../../assets/svg/home.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";
import { ReactComponent as UniversityCap } from "../../assets/svg/universityCap.svg";
import { ReactComponent as University } from "../../assets/svg/university.svg";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { ReactComponent as Scheduling } from "../../assets/svg/scheduling.svg";
import universityLogo from "../../assets/avatar.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SideNavHeaderImage,
  SideNavHeaderImageSubTitle,
} from "../../pages/analyticalDashboardPage/commonStyles";
import { CONSTANTS } from "../../constants/constants";
import { AiOutlineLogout } from "react-icons/ai";
import { logout } from "../../actions/auth";
import { updateGlobalLoader } from "../../reducers/universityLoadingReducer";
import { isAuthorizedToAccess } from "../../utilities/commonFunctions";
import ConfirmationDialog from "../alertModal/confirmation-dialog";
import { useTranslation } from "react-i18next";
import englishFlag from "../../assets/en.png";
import malayFlag from "../../assets/my.png";

const HeaderWrapper = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;

  > img {
    margin-right: 10px;
    max-width: 30px;
    cursor: pointer;
    &:last-child() {
      margin-right: 0px;
    }
  }
`;

const ProfileLogo = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 28px;
    width: 28px;
  }
`;

const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
  @media (max-width: 576px) {
    width: 25px;
    height: 25px;
    img {
      width: 25px;
      height: 25px;
    }
  }
`;

const OffcanvasWrapper = styled(Navbar.Offcanvas)`
  z-index: 1 !important;
  max-width: 348px !important;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
`;
const UniversityLogo = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 28px;
    width: 28px;
  }
  @media (max-width: 576px) {
    width: 25px;
    height: 25px;
    img {
      width: 22px;
      height: 22px;
    }
  }
`;

const SideNavHeader = styled(Offcanvas.Header)`
  padding: 0px;
`;

const OffcanvasBody = styled(Offcanvas.Body)`
  padding-right: 0px;
  padding-left: -30px;
`;

const CustomNavLink = styled.span`
  margin-left: 50px;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  letter-spacing: 1px;
  color: #acacac;
  text-decoration: none;

  .active {
    color: #d04c8d;
    border-right: 5px solid #d04c8d;
  }
  a {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 42px auto;
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    color: #acacac;
    text-decoration: none;
    svg {
      width: 22px;
      height: 20px;
      margin-top: 2px;
    }
    span {
      display: inline-block;
    }
    &:hover {
      color: #d04c8d;
      border-right: 5px solid #d04c8d;
    }
  }
`;

const NavbarToggle = styled(Navbar.Toggle)`
  border-color: transparent !important;
  #sidebarCollapse {
    width: 36px;
    height: 34px;
    margin: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    background: none;
    box-shadow: none;
    outline: none !important;
    border: 5px solid #d04c8d;
    right: 15px;
    top: 15px;
    background: #d04c8d;
    border-radius: 5px;
    padding: 5px;
    @media (max-width: 576px) {
      width: 24px;
      height: 24px;
    }
  }
  #sidebarCollapse span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    @media (max-width: 576px) {
      height: 1.5px;
    }
  }
  #sidebarCollapse span:nth-child(1) {
    top: 0px;
    @media (max-width: 576px) {
      top: 1px;
    }
  }
  #sidebarCollapse span:nth-child(2),
  #sidebarCollapse span:nth-child(3) {
    top: 10px;
    @media (max-width: 576px) {
      top: 6px;
    }
  }
  #sidebarCollapse span:nth-child(4) {
    top: 20px;
    @media (max-width: 576px) {
      top: 11px;
    }
  }
  #sidebarCollapse.active span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
  }
  #sidebarCollapse.active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #sidebarCollapse.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #sidebarCollapse.active span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
  }
`;

const OffcanvasBackdrop = styled.div`
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 00;
`;

const DropdownList = styled.span`
  padding-left: 10px;
`;
const MenuSpanText = styled.p`
  // width: 150px;
  font-size: 18px;
  white-space: nowrap;
`;

const MenuSpanTextNew = styled.p`
  // width: 150px;
  font-size: 18px;
  white-space: nowrap;
`;

export default function TopNavbar({ enableToggle }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(!show);
  React.useEffect(() => {
    handleClose();
  }, [enableToggle]);

  const validateAuthorization = (authorizedFor) => {
    return isAuthorizedToAccess(authorizedFor, user);
  };

  const adminCheck = () => {
    if (user && user.roles && user.roles.length) {
      console.log(user.roles.find((x) => x.name === "University Admin"));
      return user.roles.find((x) => x.name === "University Admin");
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    const { pathname } = location;

    if (enableToggle) {
      handleClose();
    }
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.href &&
      !(
        window.location.href.includes("/analytical-dashboard/?") ||
        window.location.href.includes("/analytical-dashboard?")
      )
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [location.pathname, enableToggle, location]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(updateGlobalLoader(true));
    dispatch(logout())
      .then(() => {
        navigate("/login", { replace: true });
      })
      .catch((error) => {});
  };

  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLanguageHandler = (lang) => {
    setSearchParams(
      {
        ...Object.fromEntries([...searchParams]),
        lang: lang,
      },
      { replace: true }
    );
    const languageValue = lang;
    i18n.changeLanguage(languageValue);
    sessionStorage.setItem("lang", languageValue);
  };

  const onUserLogoutConfirmation = () => {
    setLogoutConfirmation(true);
  };

  return (
    <>
      {enableToggle && show && <OffcanvasBackdrop onClick={handleClose} />}
      <Navbar expand={false} className="p-0">
        <Container fluid className="justify-content-end">
          <Navbar.Brand></Navbar.Brand>
          <HeaderWrapper>
            <Navbar>
              <Nav classname="margin_right_63">
                <Nav.Link>
                  <Dropdown className="dropdown__toggle">
                    <Dropdown.Toggle variant="">
                      <FlagLogo>
                        <img
                          src={i18n.language === "my" ? malayFlag : englishFlag}
                          alt="profile-logo"
                        />
                      </FlagLogo>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => changeLanguageHandler("en")}
                      >
                        <img
                          width={32}
                          height={32}
                          src={englishFlag}
                          alt="profile-logo"
                        />
                        <DropdownList>{"English"}</DropdownList>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => changeLanguageHandler("my")}
                      >
                        <img
                          width={30}
                          height={30}
                          src={malayFlag}
                          alt="profile-logo"
                        />
                        <DropdownList>{"Malay"}</DropdownList>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Link>
                {location.pathname == "/" ? (
                  <>
                    <Nav.Link href="https://www.mynext.my/faqs" target="_blank">
                      <Help />
                    </Nav.Link>
                    <Nav.Link>
                      <Dropdown className="dropdown__toggle">
                        <Dropdown.Toggle variant="">
                          <UniversityLogo>
                            <img
                              src={user?.avatar ? user?.avatar : universityLogo}
                              alt="university-logo"
                            />
                          </UniversityLogo>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            onClick={onUserLogoutConfirmation}
                          >
                            <AiOutlineLogout />
                            <DropdownList>{t("Logout")}</DropdownList>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link
                      href="https://www.mynext.my/faq-student"
                      target="_blank"
                    >
                      <Help />
                    </Nav.Link>
                    <Nav.Link>
                      <Dropdown className="dropdown__toggle">
                        <Dropdown.Toggle variant="">
                          <UniversityLogo>
                            <img
                              src={user?.avatar ? user?.avatar : universityLogo}
                              alt="university-logo"
                            />
                          </UniversityLogo>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            onClick={onUserLogoutConfirmation}
                          >
                            <AiOutlineLogout />
                            <DropdownList>{t("Logout")}</DropdownList>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar>
          </HeaderWrapper>
          {enableToggle && (
            <NavbarToggle onClick={toggleShow}>
              <span
                id="sidebarCollapse"
                className={`float-end ${show ? "active" : ""}`}
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
                aria-label="Toggle menu"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </NavbarToggle>
          )}
          <OffcanvasWrapper
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            backdrop={false}
            scroll={true}
            show={!enableToggle ? true : show}
          >
            <SideNavHeader>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <Link to="/">
                  <SideNavHeaderImage>
                    <SideNavHeaderImageSubTitle>
                      {t("UNIVERSITY")}
                    </SideNavHeaderImageSubTitle>
                  </SideNavHeaderImage>
                </Link>
              </Offcanvas.Title>
            </SideNavHeader>
            <OffcanvasBody>
              {user && user.roles && user.roles.length && (
                <Nav className="justify-content-center flex-grow-1">
                  {validateAuthorization(CONSTANTS.HOME_AUTHORIZATION_FOR) && (
                    <CustomNavLink>
                      <NavLink exact to="/">
                        <Home />
                        <MenuSpanText>{t("Dashboard")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {adminCheck() && (
                    <CustomNavLink>
                      <NavLink to="student-management">
                        <UniversityCap />
                        <MenuSpanTextNew>
                          {t("Student Management")}
                        </MenuSpanTextNew>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {/* {validateAuthorization(
                    CONSTANTS.USER_MANAGEMENT_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="user-management">
                        <User />
                        <MenuSpanText>{t("User Management")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )} */}
                  {validateAuthorization(
                    CONSTANTS.ANALYTICAL_DASHBOARD_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="analytical-dashboard">
                        <Analytical />
                        <MenuSpanText>{t("Analytical Dashboard")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {validateAuthorization(
                    CONSTANTS.CUSTOM_REPORT_BUILDER_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="customReportBuilder">
                        <CustomReportBuilder />
                        <MenuSpanText>
                          {t("Custom Report Builder")}
                        </MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {validateAuthorization(
                    CONSTANTS.COACHING_DASHBOARD_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="coaching-dashboard">
                        <UniversityCap />
                        <MenuSpanText>{t("Coaching Dashboard")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {validateAuthorization(
                    CONSTANTS.SCHEDULING_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="scheduling">
                        <Scheduling />
                        <MenuSpanText>{t("Scheduling")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {validateAuthorization(
                    CONSTANTS.UNIVERSITY_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="university">
                        <University />
                        <MenuSpanText>{t("My University")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                  {validateAuthorization(
                    CONSTANTS.SETTINGS_AUTHORIZATION_FOR
                  ) && (
                    <CustomNavLink>
                      <NavLink to="settings">
                        <Settings />
                        <MenuSpanText>{t("Settings")}</MenuSpanText>
                      </NavLink>
                    </CustomNavLink>
                  )}
                </Nav>
              )}
            </OffcanvasBody>
          </OffcanvasWrapper>
        </Container>
      </Navbar>
      <ConfirmationDialog
        id={0}
        show={logoutConfirmation}
        message={t("Are you sure want to logout?")}
        onAccept={() => onLogout()}
        closeModal={() => setLogoutConfirmation(false)}
      />
    </>
  );
}
