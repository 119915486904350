import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../constants/apiRoutes";
import { setMessage } from "../reducers/messageReducer";
import { getError } from "../services/CommonApi";
import userManagementService from "../services/userManagement.service";
import AuthService from "../services/auth.service";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DRILLDOWN_API_MAP } from "../pages/analyticalDashboardV2/constants/ad2Constants";


export const getUsers = createAsyncThunk(
  API_ROUTES.GET_UNIVERSITY_USERS,
  async (_, thunkAPI) => {
    try {
      const { search, page, perPage, sortField, sortDirection, roles } =
        thunkAPI.getState().userManagement;
      const params = {};
      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }
      const usersResponse = await userManagementService.getUsers(
        params,
        filtering,
        null
      );
      console.log("userrr", usersResponse);
      if (
        usersResponse.data.results &&
        usersResponse.data.results.length !== 0
      ) {
        return { data: usersResponse.data };
      } else {
        return { data: [] };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
); export const getDrillDownDataTable = createAsyncThunk(
  API_ROUTES.GET_DRILLDOWN_DATA,
  async (urlParams, thunkAPI) => {
    console.log("this workmj g", urlParams)
    try {
      // const {url} = getDrillDownRoute();
      console.log("takeDaya", urlParams)

      var params = urlParams.params;
      const { search, page, perPage, sortField, sortDirection, roles } =
        thunkAPI.getState().drillDownTableReducer;

      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
        // params.page_size = 10000;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }

      const usersResponse = await userManagementService.getDrilldown(
        urlParams.url,
        {
          params: params,
          controller: null
        }
      );
      console.log("userDrill", urlParams.url);

      if (urlParams.url == "dashboard/university/analytical/demographic/registered-students-list/") {
        if (
          usersResponse.data.results &&
          usersResponse.data.results.length !== 0
        ) {
          // console.log("if working",usersResponse.data);
          var dataResponse = usersResponse.data;
          // if(dataResponse.length > 0){
            for(var i in dataResponse.results){
              if(dataResponse.results[i].internship_status === "NA"){
                dataResponse.results[i].internship_status = "Not Applied";
              }
               
             }
          // }
          
          return { data: usersResponse.data };
        } else {
          return { data: [] };
        }
      }
      else {
        if (
          usersResponse.data.data.results &&
          usersResponse.data.data.results.length !== 0
        ) {
          var dataResponse = usersResponse.data.data;
          // console.log("if working",usersResponse.data.data.results);

          // if(dataResponse.length > 0){
                 for(var i in dataResponse.results){
                  console.log("dataResponse",dataResponse.results[i])
            if(dataResponse.results[i].internship_status === "NA"){
              dataResponse.results[i].internship_status = "Not Applied";
            }
             
           }
          // }
     
          return { data: dataResponse};
        } else {
          return { data: [] };
        }
      }


    } catch (error) {
      console.log('rejectedError', error)
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUser = createAsyncThunk(
  `POST:${API_ROUTES.CREATE_UNIVERSITY_USER}`,
  async (userData, thunkAPI) => {
    try {
      const { user, roles } = userData;
      const data = await userManagementService.createUser({
        user_list: user.map((x) => ({ name: x.name, email: x.email })),
        roles: roles.map((x) => x.value),
      });
      if (data && data.status === 200) {
        return true;
      } else {
        if (data && data.user_list && data.user_list.length) {
          const emailExistsList = data.user_list.map((_, index) => user[index]);
          return emailExistsList;
        }
        return { created: false, data: data };
        //throw new Error("User creation failed.");
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createUserByUniversityId = createAsyncThunk(
  `POST:${API_ROUTES.CREATE_UNIVERSITY_USER}`,
  async ({ userData, id }, thunkAPI) => {
    try {
      const { user, roles } = userData;

      const data = await userManagementService.createUserByUniversity(
        {
          user_list: user.map((x) => ({ name: x.name, email: x.email })),
          roles: roles.map((x) => x.value),
        },
        id
      );

      if (data && data.status === 200) {
        return true;
      } else {
        if (data && data.user_list && data.user_list.length) {
          const emailExistsList = data.user_list.map((_, index) => user[index]);
          return emailExistsList;
        }
        throw new Error("User creation failed.");
      }
    } catch (error) {
      const message = getError(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getUsersByUniversity = createAsyncThunk(
  API_ROUTES.GET_UNIVERSITY_USER_BY_ID,
  async (id, thunkAPI) => {
    try {
      const { search, page, perPage, sortField, sortDirection, roles } =
        thunkAPI.getState().userManagement;
      const params = {};
      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }
      const usersResponse = await userManagementService.getUserById(id);

      var testobj = {
        count: usersResponse.data.count,
        next: null,
        previous: null,
        results: usersResponse.data.results,
      };

      // if (usersResponse.data && usersResponse.data.length !== 0) {
      return { data: testobj };
      // } else {
      //   return { data: [] };
      // }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getUsersByUniversityManagment = createAsyncThunk(
  API_ROUTES.GET_UNIVERSITY_USERS_MANAGMENT,
  async (id, thunkAPI) => {
    try {
      const { search, page, perPage, sortField, sortDirection, roles, registration, is_assessment_done } =
        await thunkAPI.getState().universityUsersManagementReducer;
      const params = {};
      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (registration) {
        params.is_registered = registration;
      }
      if (is_assessment_done) {
        params.is_assessment_done = is_assessment_done;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }
      const usersResponse = await userManagementService.getUserManagmentUsers(id, params, null);
      var testobj = {
        count: usersResponse.data.count,
        next: null,
        previous: null,
        results: usersResponse.data.results,
      };

      // if (usersResponse.data && usersResponse.data.length !== 0) {
      return { data: testobj };
      // } else {
      //   return { data: [] };
      // }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
