import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthorizedToAccess } from "../../utilities/commonFunctions";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RequireAuth({ children, authorizationFor }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang !== null) {
      i18n.changeLanguage(lang);
    } else {
      setSearchParams({ lang: i18n.language }, { replace: true });
    }
  }, [i18n.language, lang]);

  return isLoggedIn && isAuthorizedToAccess(authorizationFor, user) ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
